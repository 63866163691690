<template>
  <div>
    <div class="uolla">
      <Header />
    </div>
    <div class="home-banner-wrapper">
      <div class="uolla">
        <div v-if="!isMobile" class="home-banner">
          <Carousel autoplay height="auto" loop :autoplay-speed="5000" arrow="hover">
            <CarouselItem v-for="(banner, index) in homeData.banner" :key="index">
              <img :src="banner" />
            </CarouselItem>
          </Carousel>
        </div>
        <div v-else class="home-banner">
          <Carousel height="auto" loop dots="none" :autoplay-speed="5000" arrow="hover">
            <CarouselItem v-for="(banner, index) in homeData.mobile_banner" :key="index">
              <img :src="banner" />
            </CarouselItem>
          </Carousel>
        </div>
      </div>
    </div>
    <div class="uolla">
      <div class="home">
        <div class="home-content home-content-bg1">
          <about-us-module />
        </div>
        <div class="home-content home-content-bg2">
          <description-module />
        </div>
        <div v-if="!isMobile" class="home-content">
          <team-module />
        </div>
        <div v-if="!isMobile" class="home-content home-content-bg2-1">
          <advantage-module />
          <risk-control-module />
        </div>
        <div v-if="isMobile" class="home-content home-content-bg3">
          <team-module />
          <advantage-module />
          <risk-control-module />
        </div>
        <div :class="[`home-content ${isMobile ? '' : 'home-content-bg3'}`]">
          <div v-if="homeData.news">
            <h3 class="home-content--title">{{homeData.news.title}}</h3>
            <CarouselCard />
          </div>

          <div v-if="homeData.contact_us">
            <h3 class="home-content--title" :style="{'margin-top': isMobile ? '140px' : '80px'}">{{homeData.contact_us.title}}</h3>
            <div class="home-content--info home-content--info__tc home-content--info__special">
              {{homeData.contact_us.content}}
              <a class="home-content--link" :href="`mailto:${homeData.contact_us.email}`">{{homeData.contact_us.email}}</a>
            </div>
            <div class="home-content--contact-us">
              <a v-for="item in homeData.contact_us.list" :key="item.name" :href="item.url" target="_blank">
                <img :src="item.image" />
              </a>
            </div>
          </div>

          <img class="footer-logo" src="@/assets/images/icon_logo.png" alt="uollar-logo" />
          <p class="copy-right">© {{new Date().getFullYear()}} Uollar Ltd. All rights reserved.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '@/mixins/mixin'
import 'intersection-observer'
import { mapActions, mapGetters } from 'vuex'
import Header from '@/components/Header'
import CarouselCard from '@/components/Carousel/index'
import AboutUsModule from './AboutUsModule'
import DescriptionModule from './DescriptionModule'
import TeamModule from './TeamModule'
import AdvantageModule from './AdvantageModule'
import RiskControlModule from './RiskControlModule'

export default {
  name: 'Home',
  mixins: [mixin],
  computed: {
    ...mapGetters([
      'locale'
    ])
  },
  watch: {
    locale(newVal) {
      if (newVal) {
        this.getUollarInfo()
      }
    }
  },
  data() {
    return {
      homeData: {}
    }
  },
  components: {
    Header,
    CarouselCard,
    AboutUsModule,
    DescriptionModule,
    TeamModule,
    AdvantageModule,
    RiskControlModule
  },
  mounted() {
    this.getUollarInfo()
  },
  methods: {
    ...mapActions({
      getHomeData: 'getHomeData'
    }),
    async getUollarInfo() {
      this.homeData = await this.getHomeData()
      this.$nextTick(() => {
        this.bindObserver()
      })
    },
    bindObserver() {
      const observer = new IntersectionObserver(changes => {
        for (const change of changes) {
          const { top, bottom } = change.intersectionRect
          if ((bottom !== 0 && top === 0) && change.target) {
            change.target.style.cssText = 'opacity: 1; transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transition: opacity 1s ease 0.3s, transform 1s ease 0.3s;'
          }
          if (top !== 0 && change.target) {
            change.target.style.cssText = 'opacity: 1; transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transition: opacity 1s ease 0.3s, transform 1s ease 0.3s;'
          }
        }
      })
      observer.POLL_INTERVAL = 100
      const domLength = document.getElementsByClassName('advantage-card').length
      for (let i = 0; i < domLength; i++) {
        const dom = document.getElementById(`advantageCard${i+1}`)
        observer.observe(dom)
      }
    }
  }
}
</script>

<style lang="scss">
@import "../../styles/variable";
.home-banner-wrapper {
  background-color: #2369FF;
}
.home {
  &-banner {
    width: 100%;
    /*height: 860px;*/
    text-align: center;
  }
  &-content {
    padding: 40px 20%;
    color: $color-white;
    &-bg1 {
      min-height: 700px;
      background: url("../../assets/images/home/content_bg1.png") left bottom no-repeat;
      background-size: 1132px 619px;
    }
    &-bg2 {
      height: 1000px;
      background: url("../../assets/images/home/content_bg2_01.png") left 168px no-repeat;
      background-size: 1920px 892px;
    }
    &-bg2-1 {
       height: 1250px;
       background: url("../../assets/images/home/content_bg2_02.png") left 0 no-repeat;
       background-size: 1920px 759px;
    }
    &-bg3 {
      height: 1200px;
      background: url("../../assets/images/home/content_bg3.png") right 230px no-repeat;
      background-size: 583px 711px;
    }
    &--risk {
      margin-top: 120px
    }
    &--title {
      margin: 40px 0;
      font-weight: 900;
      font-size: $fontSize-xxl;
      line-height: $lineHeight-xl;
    }
    &--info {
      margin-bottom: 40px;
      font-weight: $fontWeight-normal;
      font-size: $fontSize-base;
      text-align: left;
      line-height: $lineHeight-lg;
      &__tc {
        text-align: center !important;
      }
    }
    &--link {
      color: #528afb;
    }
    .advantage {
      padding-top: 20px;
      &__right {
        margin-left: 52%;
      }
      &-card {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 32px 0;
        margin-bottom: 20px;
        position: relative;
        width: 560px;
        min-height: 140px;
        text-align: left;
        opacity: 0;
        transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 300, 0, 0, 1);
        background: linear-gradient(90deg, rgba(13, 31, 73, 0.8) 0%, rgba(13, 31, 73, 0) 97.32%);
        border-radius: 88px;
        &--icon {
          position: relative;
          left: -10px;
          width: 48px;
          height: 48px;
        }
        &--title {
          margin-bottom: 4px;
          font-weight: $fontWeight-bold;
          font-size: $fontSize-md;
          line-height: $lineHeight-lg;
        }
        &--desc {
          font-size: $fontSize-base;
          line-height: $lineHeight-lg;
        }
      }
    }
    .team-card {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-wrap: wrap;
      margin: 20px 0 60px;
      &--item {
        position: relative;
        margin: 0 0 40px 0;
        width: 300px;
        height: 400px;
        overflow: hidden;
        border-radius: 10px;
        background: #0D1F49;
        cursor: pointer;
        &-photo {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          border-radius: 10px;
          background: #80848a;
        }
        &-info {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100px;
          border-radius: 0 0 10px 10px;
          background: url("../../assets/images/home/team_info_bg.png") center center no-repeat;
          background-size: 100% 100%;
          &--name {
            margin-top: 20px;
            font-weight: 700;
            font-size: 24px;
            text-align: center;
            color: #fff;
          }
          &--position {
            padding: 0 10px;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #fff;
            opacity: 0.7;
          }
        }
      }
    }
    &--risk-control {
      display: flex;
      justify-content: space-around;
      div {
        flex: 1;
        &:hover {
          color: #9CBCFF;
          text-shadow: 0 0 8px rgba(96, 146, 255, 0.6);
        }
      }
      &__info {
        padding-top: 20px;
        font-size: $fontSize-base;
        line-height: $lineHeight-lg;
        text-align: center;
        color: #fff;
      }
      &__desc {
        margin-top: 20px;
      }
    }
    &--contact-us {
      width: 350px;
      display: flex;
      margin: 0 auto 100px;
      justify-content: space-around;
      cursor: pointer;
      img {
        width: 40px;
        height: 40px;
      }
    }

    .footer-logo {
      width: 150px;
    }

    .copy-right {
      margin-top: 30px;
      font-size: $fontSize-xs;
      line-height: 12px;
      opacity: 0.6;
    }
  }
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {

  }
  @media screen and (max-width: 750px) {
    .home {
      background-color: #08152f;
      &-banner {
        height: auto;
        background-color: unset;
      }
      &-content {
        padding: 30px;
        &-bg1 {
          min-height: 670px;
          height: auto;
          background: url("../../assets/images/home/content_mp_bg1.png") left 60px no-repeat;
          background-size: cover;
        }
        &-bg2 {
          min-height: 1260px;
          background: url("../../assets/images/home/content_mp_bg2.png") 0 bottom no-repeat;
          background-size: 100%;
        }
        &-bg3 {
          padding-right: 0;
          height: 2380px;
          background: url("../../assets/images/home/content_mp_bg3.png") 0 860px no-repeat;
          background-size: 100%;
        }
        &--team {
          margin-top: -40px
        }
        &--risk {
          margin-top: 540px;
        }
        &--title {
          margin: 30px 0 20px;
          font-size: $fontSize-xl;
          line-height: $lineHeight-md;
        }
        &--info {
          padding-left: 0 !important;
          margin-bottom: 20px;
          font-size: $fontSize-xs;
          line-height: 24px;
          text-align: left;
          &__special {
            margin-bottom: 44px;
            font-weight: $fontWeight-bold;
            font-size: $fontSize-base;
            line-height: $lineHeight-lg;
          }
        }
        .advantage {
          &__right {
            margin-left: 0;
          }
          &-card {
            padding: 20px 0;
            width: 345px;
            min-height: 82px;
            &--icon {
              left: -10px;
              width: 36px;
              height: 36px;
            }
            &--title {
              margin-bottom: 12px;
              font-size: $fontSize-base2;
              line-height: $lineHeight-sm;
            }
            &--desc {
              padding-right: 30px;
              font-size: $fontSize-xs;
              line-height: 18px;
            }
          }
        }
        &--risk-control {
          flex-direction: column;
          div {
            margin-bottom: 68px;
          }
        }
        &--contact-us {
          width: 80%;
          flex-wrap: wrap;
          justify-content: center;
          img {
            margin: 0 20px 40px;
          }
        }
      }
    }
    .team-card{
      flex-wrap: nowrap;
      overflow-x: auto;
      justify-content: left;
    }
    .team-card--item {
      flex-shrink: 0;
      margin-right: 20px;
      width: 230px;
      height: 330px;
    }
  }
}
</style>