import browser from '@/lib/browser'

const mixin = {
  data() {
    return {
      isMobile: window.innerWidth <= 750 || !!browser.result.ios || !!browser.result.android
    }
  },
  computed: {
  },
  created() {
    window.addEventListener('resize', this.calculateSize)
  },
  destroyed() {
    window.removeEventListener('resize', this.calculateSize)
  },
  methods: {
    calculateSize() {
      let width = window.innerWidth
      this.isMobile = width <= 750 || !!browser.result.ios || !!browser.result.android
    }
  }
}

export default mixin
