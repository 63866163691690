import Vue from 'vue';
import VueI18n from 'vue-i18n';
import store from '@/store/index'
import en from './en-US';
import zh from './zh-CN';
import zhHK from './zh-HK';

Vue.use(VueI18n)

const i18n = new VueI18n(({
  locale: localStorage.getItem('uollar-locale') || store.state.locale,
  messages: {
    'zh-CN': zh,
    'zh-HK': zhHK,
    'en-US': en
  }
}))

export default i18n;