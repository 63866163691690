<template>
  <div v-if="homeData.team_intro" class="advantage advantage__right">
    <div v-for="(item, index) in homeData.team_intro.list" :key="index" class="advantage-card" :id="`advantageCard${descriptionLength + index + 1}`">
      <img class="advantage-card--icon" :src="item.image" />
      <div>
        <h4 class="advantage-card--title">{{item.title}}</h4>
        <p class="advantage-card--desc">{{item.content}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: "AdvantageModule",
  computed: {
    ...mapGetters([
      'homeData'
    ]),
    descriptionLength() {
      const { description } = this.homeData
      return description && description.list && description.list.length || 0
    }
  }
}
</script>

<style scoped>

</style>