import service from './service'

export function getUollarInfo() {
  return service({
    method: 'get',
    url: 'api/index'
  })
}

export function getNewsDetail(options) {
  const { id } = options.query
  return service({
    method: 'get',
    url: `api/news/detail?id=${id}`
  })
}

export function getTeam() {
  return service({
    method: 'get',
    url: `api/news/detail?id=${id}`
  })
}