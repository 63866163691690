const lang = {
  name: '首页',
  common: {
    nav: {
      home: '首页'
    }
  },
  home: {
    aboutUs: '关于我们',
    aboutUsIntroduce1: 'Uollar 致力于以创新金融科技服务全球用户，将人工智能、大数据、区块炼等最新技术应用于数字金融生态中，提供数字原生的金融服务以及相关基础设施，帮助机构及个人用户创造和积累数位财富，畅享财务自由新生活，以智能科技带领用户通往未来金融世界。',
    aboutUsIntroduce2: 'Uollar团队拥有丰富的金融投资和服务经验，国际化的背景保证了前瞻性的思维，对最数字金融行业的发展有敏锐的触觉，能更有效地应对市场的变化。',
    description: '平台描述',
    descriptionIntroduce1: 'Uollar 提供综合加密金融服务。我们帮助个人、交易所、矿池、代币发行人、对冲基金和机构等, 进行交易和管理加密货币。<br />Uollar 是您通向加密金融领域的首选。',
    richProducts: '多样化理财产品',
    richProductsDesc: 'Uollar 提供多样化的理财产品可供使用者选择。',
    fixIncomeOrientedProducts: '固定收益类产品',
    fixIncomeOrientedProductsDesc: '其中的一个主要类别是固定收益类产品，简单稳定，而且上手简单无障碍。',
    intuitiveApp: '精心设计的APP',
    intuitiveAppDesc: '通过 Uollar 精心设计的 APP，可以通过直观简单的操作来购买这些产品。',
    ourTeam: '团队介绍',
    ourTeamIntroduce1: 'Uollar 团队在金融投资和服务方面有著丰富经验。',
    discover: '发掘',
    discoverDesc: '发掘市场上的优秀产品，并提供给使用者选择',
    incubate: '孵化',
    incubateDesc: '发现、培育及孵化有潜力的投资团队，从量化到固收到其它。',
    cooperate: '合作',
    cooperateDesc: '与多家交易所合作，有效利用虚拟货币，取得稳定收益。',
    riskControl: '风险控制',
    riskControlDesc: '信息安全监控。',
    riskControlStep1: '业务风控系统',
    riskControlStep2: '安全状态侦测',
    riskControlStep3: '专业的资讯安全团队',
    riskControlStep4: '完善的资讯安全防护体系',
    riskControlStep5: '可靠的管理系统',
    news: '相关报导',
    newsCardTitle: '软银向AI公司Qraft投资1.46亿美元',
    newsCardDesc: '当地时间周一，美股收盘涨跌不一，纳指日内跌幅一度接近3%，收盘小幅上涨，美债继续遭抛售。日本软银集团将向人工智慧创业公司Qraft Technologies投入1.46亿美...',
    readMore: 'READ MORE →',
    contactUs: '联络我们',
    contactUsDesc: '保持联系'
  }
}
export default lang