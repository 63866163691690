import axios from 'axios'
import store from '@/store/index'


// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_AXIOS_BASE_URL, // api 的 base_url
  timeout: 30000, // 请求超时时间
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
    'Accept-Language': store.state.locale || 'zh-HK'
  }
})

// request拦截器
service.interceptors.request.use(
  config => {
    config.headers['Accept-Language'] = store.state.locale
    return config
  },
  error => {
    console.log(error)
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    if (response.status === 200 && res.success) {
      return res.data;
    } else {
      return Promise.reject(res);
    }
  },
  error => {
    console.log('err' + error);
    return Promise.reject(error);
  }
);

export default service