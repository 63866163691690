<template>
  <div v-if="homeData.about">
    <h3 class="home-content--title">{{homeData.about.title}}</h3>
    <p class="home-content--info" style="padding-left: 42%" v-html="homeData.about.content.replaceAll('\n', '<br />')"></p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AboutUsModule',
  computed: {
    ...mapGetters([
      'homeData'
    ])
  }
}
</script>

<style scoped>

</style>