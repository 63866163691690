<template>
  <div v-if="homeData.description">
    <div>
      <h3 class="home-content--title">{{homeData.description.title}}</h3>
      <p class="home-content--info home-content--info__tc" v-html="homeData.description.content"></p>
    </div>
    <div class="advantage">
      <div v-for="(item, index) in homeData.description.list" :key="index" class="advantage-card" :id="`advantageCard${index + 1}`">
        <img class="advantage-card--icon" :src="item.image" />
        <div>
          <h4 class="advantage-card--title">{{item.title}}</h4>
          <p class="advantage-card--desc">{{item.content}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'DescriptionModule',
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'homeData'
    ])
  }
}
</script>

<style scoped>

</style>