<template>
  <div class="card-carousel-wrapper">
    <div class="card-carousel--nav__left" @click="moveCarousel(-1)" :disabled="atHeadOfList">
      <img src="@/assets/images/icon_left_circle.png" />
    </div>
    <div class="card-carousel">
      <div class="card-carousel--overflow-container">
        <div class="card-carousel-cards" :style="{ transform: 'translateX' + '(' + currentOffset + 'px' + ')'}">
          <div class="card-carousel--card" v-for="(item, index) in homeData.news.list" :key="index">
            <div class="card-carousel--card-content" @click="goDetail(item.id)">
              <div>
                <h4 class="card-carousel--card-content__title">{{item.title}}</h4>
                <p class="card-carousel--card-content__desc" v-html="item.content"></p>
              </div>
              <div class="card-carousel--card-footer">
                <p>{{item.date}}</p>
                <p>READ MORE →</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-carousel--nav__right" @click="moveCarousel(1)" :disabled="atEndOfList">
      <img src="@/assets/images/icon_right_circle.png" />
    </div>
  </div>
</template>

<script>
import mixin from '@/mixins/mixin'
import { mapGetters } from 'vuex'
export default {
  name: 'Carousel',
  mixins: [mixin],
  data() {
    return {
      currentOffset: 0,
      paginationFactor: 383,
      newsLength: 5
    }
  },
  computed: {
    ...mapGetters([
      'homeData'
    ]),
    windowSize() {
      return this.isMobile ? 1 : 3
    },
    atEndOfList() {
      return this.currentOffset <= (this.paginationFactor * -1) * (this.newsLength - this.windowSize);
    },
    atHeadOfList() {
      return this.currentOffset === 0;
    },
  },
  methods: {
    moveCarousel(direction) {
      // Find a more elegant way to express the :style. consider using props to make it truly generic
      if (direction === 1 && !this.atEndOfList) {
        this.currentOffset -= this.paginationFactor;
      } else if (direction === -1 && !this.atHeadOfList) {
        this.currentOffset += this.paginationFactor;
      }
    },
    goDetail(id) {
      this.$router.push(`/newsDetail?id=${id || 5}`)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./card-carousel";
</style>