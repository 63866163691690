<template>
  <div class="detail-wrapper">
    <div class="header-wrapper">
      <div class="uolla">
        <Header />
      </div>
    </div>
    <div v-if="detail.news_detail" class="detail-content">
      <h3 class="detail-content--title">{{detail.news_detail.title}}</h3>
      <p class="detail-content--time">{{detail.news_detail.publish_date}}</p>
      <p v-html="detail.news_detail.content.replaceAll('\n', `<p> </p>`)"></p>
    </div>
    <div class="footer">
      <button class="btn-return" @click="goBack">
        <img class="icon-arrow-left" src="@/assets/images/icon_arrow_down.png" /> Return
      </button>
      <p class="copy-right">© {{new Date().getFullYear()}} Uollar Ltd. All rights reserved.</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Header from '@/components/Header'
import { getNewsDetail } from '@/lib/http/request'
export default {
  name: 'Detail',
  computed: {
    ...mapGetters([
      'locale'
    ])
  },
  watch: {
    locale(newVal) {
      if (newVal) {
        this.getNewsDetail()
      }
    }
  },
  components: {
    Header
  },
  data() {
    return {
      id: null,
      detail: {}
    }
  },
  mounted() {
    this.id = this.$route.query.id
    this.getNewsDetail()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    getNewsDetail() {
      getNewsDetail({
        query: {
          id: this.id
        }
      }).then(res => {
        this.detail = res
      }).catch(e => {
        console.log(e)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f0f0f0;
  .header-wrapper {
    height: 100px;
    background-color: #0a162f;
  }
  .detail-content {
    overflow-y: auto;
    flex: 1;
    margin: 20px auto;
    padding: 40px;
    font-size: 18px;
    line-height: 36px;
    color: rgba(0, 0, 0, 0.75);
    opacity: 0.7;
    background-color: #fff;
    &--title {
      font-weight: bold;
      font-size: 24px;
      line-height: 36px;
      color: rgba(0, 0, 0, 0.85);
    }
    &--time {
      margin: 20px 0;
      font-size: 14px;
      line-height: 14px;
      color: rgba(0, 0, 0, 0.45);
      opacity: 0.7;
    }
  }
  .footer {
    width: 100%;
    height: 120px;
    text-align: center;

    .btn-return {
      position: relative;
      padding-left: 16px;
      width: 100px;
      height: 40px;
      text-align: center;
      color: #fff;
      border: 0;
      cursor: pointer;
      background-color: #0a162f;
      .icon-arrow-left {
        position: absolute;
        left: 14px;
        top: 14px;
        width: 12px;
        height: 12px;
        transform: rotate(90deg);
      }
    }
    .copy-right {
      margin-top: 30px;
      font-size: 12px;
      line-height: 12px;
      color: rgba(0, 0, 0, 0.45);
    }
  }
}
@media screen and (max-width: 750px) {
  .detail-wrapper .footer {
    height: 100px;
  }
}
</style>