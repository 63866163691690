<template>
  <div class="uolla-wrapper">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  created() {
    if (!localStorage.getItem('uollar-locale')) {
      localStorage.setItem('uollar-locale', 'zh-HK')
    }
  }
}
</script>

<style lang="scss">
@import "styles/variable";
body {
  margin: 0;
}
.uolla-wrapper {
  background-color: #0a162f;
}
.uolla {
  position: relative;
  max-width: $max-width;
  margin: 0 auto;
  text-align: center;
  font-family: $fontFamily-normal;

  .ivu-carousel {
    /*height: 860px;*/
    .ivu-carousel-arrow {
      width: 50px;
      height: 50px;
      &>* {
        vertical-align: middle;
      }
      .ivu-icon-ios-arrow-back, .ivu-icon-ios-arrow-forward {
        font-size: $fontSize-lg;
      }
    }
    .ivu-carousel-dots-inside {
      bottom: 8px;
    }
    .ivu-carousel-list {
      background-color: #0a162f
    }
    .ivu-carousel-item {
      img {
        width: 100%;
        height: 100%;
      }
    }
    .ivu-carousel-dots li button {
      width: 24px;
      height: 8px;
      border-radius: 2px;
      background-color: #fff;
    }
    .ivu-carousel-dots li.ivu-carousel-active>button {
      width: 30px;
    }
  }
}
@media screen and (max-width: 750px) {
  .uolla {
    overflow-x: hidden;
    .ivu-carousel {
      height: auto;
    }
    .ivu-carousel-item {
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
