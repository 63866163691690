const lang = {
  name: 'Home',
  common: {
    nav: {
      home: 'Home'
    }
  },
  home: {
    aboutUs: 'ABOUT UOLLAR',
    aboutUsIntroduce1: 'Uollar is committed to serving the global investors with its innovative financial\n' +
    'technologies including artificial intelligence, big data and blockchain to the digital\n' +
    'financial ecology. Uollar provides digital financial services and related\n' +
    'infrastructure, helping institutional clients and individual investors create and\n' +
    'accumulate digital wealth and enjoy a new life of financial freedom, leading users\n' +
    'to the future financial world with intelligent technology.',
    aboutUsIntroduce2: 'The team of Uollar has profound experience in financial investments and services.\n' +
    'Its international background ensures its forward-looking thinking. And the team\n' +
    'has a better sense of the latest developments of the digital financial industry and is\n' +
    'able to deal with changes in the market more efficiently.',
    description: 'DESCRIPTION',
    descriptionIntroduce1: 'Uollar provides integrated crypto financial services. We help individuals,\n' +
    'exchanges, mining pools, token issuers, hedge funds and institutions access,\n' +
    'trade and manage cryptocurrencies. We are your gateway to crypto finance.',
    richProducts: 'RICH PRODUCTS',
    richProductsDesc: 'Uollar offers a wild range of products.',
    fixIncomeOrientedProducts: 'FIX-INCOME ORIENTED PRODUCTS',
    fixIncomeOrientedProductsDesc: 'A major part of the products are fix-income oriented products to eliminate the obstacle of investment.',
    intuitiveApp: 'INTUITIVE APP',
    intuitiveAppDesc: 'An intuitive app is provided on which all the investment action can be made on your phone.',
    ourTeam: 'OUR TEAM',
    ourTeamIntroduce1: 'The team of Uollar has profound experience in financial investments and services.',
    discover: 'DISCOVER',
    discoverDesc: 'Discover great products to invest on the market. ',
    incubate: 'INCUBATE',
    incubateDesc: 'Incubate great teams range from quant to fix-income and etc.',
    cooperate: 'COOPERATE',
    cooperateDesc: 'Work with exchanges to gain a steady return.',
    riskControl: 'RISK CONTROL',
    riskControlDesc: 'Information security monitoring.',
    riskControlStep1: 'Operational risk management system',
    riskControlStep2: 'To ensure asset security',
    riskControlStep3: 'Professional security team',
    riskControlStep4: 'Robust information security monitoring',
    riskControlStep5: 'Reliable management system',
    news: 'NEWS',
    newsCardTitle: 'MUSK LIVE RECORDING: AUTOPILOT, COLONIZATION OF MARS, DIGITAL CURRENCY',
    newsCardDesc: 'On December 28, 2021, Lex Fridman, a well-known technology blogger on youtube, talked to musk, from SpaceX\'s manned spacecraft, raptor...',
    readMore: 'READ MORE →',
    contactUs: 'CONTACT US',
    contactUsDesc: 'STAY IN TOUCH'
  }
}
export default lang