<template>
  <div class="header">
    <img class="header-logo" src="@/assets/images/icon_logo.png" alt="uollar-logo" @click="goHome" />
    <div class="nav"></div>
    <div class="language-toolbar">
      <Dropdown @on-click="handleSelectedLanguage">
        <img class="icon-language" src="@/assets/images/icon_language.png" />
        {{languages[selectedIndex].name}}
        <img class="icon-arrow-down" src="@/assets/images/icon_arrow_down.png" />
        <DropdownMenu slot="list" >
          <DropdownItem v-for="(lang, index) in languages" :key="lang.key" :name="index">{{lang.name}}</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const LANGUAGES = [
  {
    name: 'English',
    key: 'en-US'
  }, {
    name: '繁体中文',
    key: 'zh-HK'
  }, {
    name: '简体中文',
    key: 'zh-CN'
  }
]
const DEFAULT_INDEX = 2
export default {
  name: 'Header',
  data() {
    return {
      selectedIndex: DEFAULT_INDEX,
      languages: LANGUAGES
    }
  },
  computed: {
    ...mapGetters([
      'locale'
    ])
  },
  created() {
    const locale = this.locale || localStorage.getItem('uollar-locale') || 'zh-HK'
    const index = this.languages.findIndex(item => item.key === locale)
    this.selectedIndex = index
  },
  methods: {
    handleSelectedLanguage(index) {
      this.selectedIndex = index
      const locale = this.languages[index].key
      localStorage.setItem('uollar-locale', locale)
      this.$i18n.locale = locale
      this.$store.commit('SET_LOCALE', locale)
      window.location.reload()
    },
    goHome() {
      this.$router.push(`/`)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/variable";
.header {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 37px 15% 15px;
  max-width: $max-width;
  height: 100px;
  font-size: $fontSize-sm;
  line-height: $lineHeight-xs;
  color: $color-white;
  z-index: 2;
  &-logo {
    width: 151px;
    height: 48px;
    cursor: pointer;
  }
  .language-toolbar {
    line-height: 50px;
    cursor: pointer;
    .icon-language, .icon-arrow-down {
      position: relative;
      top: 3px;
    }
    .icon-language {
      margin-right: 8px;
      width: 15px;
      height: 15px;
    }
    .icon-arrow-down {
      margin-left: 6px;
      width: 12px;
      height: 12px;
    }
  }
}
@media screen and (max-width: 750px) {
  .header {
    padding: 20px 30px;
    &-logo {
      width: 107px;
      height: 32px;
    }
    .language-toolbar {
      .icon-language, .icon-arrow-down {
        position: relative;
      }
      .icon-language {
        top: 4px;
        margin-right: 4px;
        width: 18px;
        height: 18px;
      }
      .icon-arrow-down {
        top: 2px;
        margin-left: 4px;
        width: 12px;
        height: 12px;
      }
    }
  }
}
</style>