<template>
  <div v-if="homeData.risk_control" class="home-content--risk">
    <div>
      <h3 class="home-content--title" style="margin-top: 300px">{{homeData.risk_control.title}}</h3>
      <p class="home-content--info home-content--info__tc">
        {{homeData.risk_control.content}}
      </p>
    </div>
    <div class="home-content--risk-control">
      <div v-for="(type, index) in homeData.risk_control.list" :key="index">
        <img :src="getImageSrc(type, index)" @mouseover="handleMouseOver(type, index)" @mouseout="handleMouseOut(type, index)" />
        <p class="home-content--risk-control__desc">{{type.title}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import imgRC1 from '@/assets/images/home/icon_risk_control1.png'
import imgRC2 from '@/assets/images/home/icon_risk_control2.png'
import imgRC3 from '@/assets/images/home/icon_risk_control3.png'
import imgRC4 from '@/assets/images/home/icon_risk_control4.png'
import imgRC5 from '@/assets/images/home/icon_risk_control5.png'
import imgRCHover1 from '@/assets/images/home/icon_risk_control1_hover.png'
import imgRCHover2 from '@/assets/images/home/icon_risk_control2_hover.png'
import imgRCHover3 from '@/assets/images/home/icon_risk_control3_hover.png'
import imgRCHover4 from '@/assets/images/home/icon_risk_control4_hover.png'
import imgRCHover5 from '@/assets/images/home/icon_risk_control5_hover.png'
export default {
  name: 'RiskControlModule',
  computed: {
    ...mapGetters([
      'homeData'
    ])
  },
  watch: {
    homeData(newVal) {
      if (newVal && newVal.risk_control) {
        const { list } = newVal.risk_control
        list.forEach((_, index) => {
          this.hoverState[index] = false
        })
      }
    }
  },
  data() {
    return {
      hoverState: {},
      imgRC1,
      imgRC2,
      imgRC3,
      imgRC4,
      imgRC5,
      imgRCHover1,
      imgRCHover2,
      imgRCHover3,
      imgRCHover4,
      imgRCHover5,
      riskControl: [{
        desc: this.$t('home.riskControlStep1'),
        icon: imgRC1,
        iconHover: imgRCHover1,
        iconCur: imgRC1
      }, {
        desc: this.$t('home.riskControlStep2'),
        icon: imgRC2,
        iconHover: imgRCHover2,
        iconCur: imgRC2
      }, {
        desc: this.$t('home.riskControlStep3'),
        icon: imgRC3,
        iconHover: imgRCHover3,
        iconCur: imgRC3
      }, {
        desc: this.$t('home.riskControlStep4'),
        icon: imgRC4,
        iconHover: imgRCHover4,
        iconCur: imgRC4
      }, {
        desc: this.$t('home.riskControlStep5'),
        icon: imgRC5,
        iconHover: imgRCHover5,
        iconCur: imgRC5
      }]
    }
  },
  methods: {
    getImageSrc(_, index) {
      // return this.hoverState[index] ? item.image_hover : item.image
      //忽略接口的图片，采用本地的
      return this.riskControl[index].iconCur;
    },
    handleMouseOver(_, index) {
      this.hoverState[index] = true
      
      let one = this.riskControl[index]
      one.iconCur = one.iconHover
      this.$set(this.riskControl, index, one)
    },
    handleMouseOut(_, index) {
      this.hoverState[index] = false
      
      let one = this.riskControl[index]
      one.iconCur = one.icon
      this.$set(this.riskControl, index, one)
    }
  }
}
</script>

<style scoped>

</style>