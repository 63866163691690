import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/pages/home/Home'
import NewsDetail from '@/pages/news/Detail'

Vue.use(Router)

// 配置路由
const routes = [{
  path: `/`,
  name: 'HOME',
  component: Home
}, {
  path: `/newsDetail`,
  name: 'NEWS',
  component: NewsDetail
}]

const router = new Router({
  mode: 'history',
  routes: routes,
  // base: '' // 路由基础路径
  base: process.env.BASE_URL
})

router.beforeEach((to, from, next) => {
  next()
})
export default router
