/**
 * 浏览器检测
 */

class Browser {
  constructor(ua) {
    this.result = this.detect(ua);
  }

  detect(ua) {
    function getFirstMatch(regex) {
      const match = ua.match(regex);
      return (match && match.length > 1 && match[1]) || "";
    }

    function getSecondMatch(regex) {
      const match = ua.match(regex);
      return (match && match.length > 1 && match[2]) || "";
    }

    const ios = getFirstMatch(/(ipod|iphone|ipad)/i).toLowerCase();
    const likeAndroid = /like android/i.test(ua);
    const android = !likeAndroid && /android/i.test(ua);
    const edgeVersion = getFirstMatch(/edge\/(\d+(\.\d+)?)/i);
    const versionIdentifier = getFirstMatch(/version\/(\d+(\.\d+)?)/i);
    let result;

    if (/opera|opr/i.test(ua)) {
      result = {
        name: "Opera",
        opera: true,
        version:
          versionIdentifier ||
          // eslint-disable-next-line
          getFirstMatch(/(?:opera|opr)[\s\/](\d+(\.\d+)?)/i)
      };
    } else if (/msie|trident/i.test(ua)) {
      result = {
        name: "Internet Explorer",
        msie: true,
        version: getFirstMatch(/(?:msie |rv:)(\d+(\.\d+)?)/i)
      };
    } else if (/chrome.+? edge/i.test(ua)) {
      result = {
        name: "Microsoft Edge",
        msedge: true,
        version: edgeVersion
      };
    } else if (/chrome|crios|crmo/i.test(ua)) {
      result = {
        name: "Chrome",
        chrome: true,
        version: getFirstMatch(/(?:chrome|crios|crmo)\/(\d+(\.\d+)?)/i)
      };
    } else if (ios) {
      result = {
        name: ios === "iphone" ? "iPhone" : ios === "ipad" ? "iPad" : "iPod"
      };
      // WTF: version is not part of user agent in web apps
      if (versionIdentifier) {
        result.version = versionIdentifier;
      }
    } else if (/firefox|iceweasel/i.test(ua)) {
      result = {
        name: "Firefox",
        firefox: true,
        // eslint-disable-next-line
        version: getFirstMatch(/(?:firefox|iceweasel)[ \/](\d+(\.\d+)?)/i)
      };
      // eslint-disable-next-line
      if (/\((mobile|tablet);[^\)]*rv:[\d\.]+\)/i.test(ua)) {
        result.firefoxos = true;
      }
    } else if (android) {
      result = {
        name: "Android",
        version: versionIdentifier
      };
    } else if (/phantom/i.test(ua)) {
      result = {
        name: "PhantomJS",
        phantom: true,
        version: getFirstMatch(/phantomjs\/(\d+(\.\d+)?)/i)
      };
    } else if (/safari/i.test(ua)) {
      result = {
        name: "Safari",
        safari: true,
        version: versionIdentifier
      };
    } else {
      result = {
        name: getFirstMatch(/^(.*)\/(.*) /),
        version: getSecondMatch(/^(.*)\/(.*) /)
      };
    }

    // set OS flags for platforms that have multiple browsers
    if (!result.msedge && (android || result.silk)) {
      result.android = true;
    } else if (ios) {
      result[ios] = true;
      result.ios = true;
    }
    return result;
  }
}

export default new Browser(navigator && navigator.userAgent);
