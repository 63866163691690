import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'
import { getUollarInfo } from '@/lib/http/request'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

// global initial state
const state = {
  locale: localStorage.getItem('uollar-locale') || 'zh-HK',
  homeData: {}
}

// global getters
const getters = {
  homeData: state => state.homeData,
  locale: state => state.locale
}

// global actions
const actions = {
  getHomeData({ commit }, params) {
    console.log(params)
    return new Promise((resolve, reject) => {
      getUollarInfo().then(res => {
        commit('SET_HOME_DATA', res)
        resolve(res)
      }).catch(e => {
        reject(e)
      })
    })
  }
}

// global mutations
const mutations = {
  SET_HOME_DATA (state, config) {
    state.homeData = config
  },
    SET_LOCALE(state, value) {
    state.locale = value
  }
}

const store = new Vuex.Store({
  state,
  actions,
  getters,
  mutations,
  strict: debug,
  plugins: debug ? [createLogger()] : []
})

export default store
