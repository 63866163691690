<template>
  <div v-if="homeData.team_intro">
    <div class="home-content--team">
      <h3 class="home-content--title">{{homeData.team_intro.title}}</h3>
      <p class="home-content--info home-content--info__tc">
        {{homeData.team_intro.content}}
      </p>
    </div>
    <div class="advantage team-card">
      <div
        v-for="(item, index) in homeData.team_list"
        :key="index"
        class="team-card--item"
        @click="handleMemberCard(item)"
      >
        <img class="team-card--item-photo" :src="item.image_cover || item.image" />
        <div class="team-card--item-info">
          <h4 class="team-card--item-info--name">{{item.realname}}</h4>
          <p class="team-card--item-info--position">{{item.position}}</p>
        </div>
      </div>
    </div>
    <div v-if="showMemberCard" class="card-modal">
      <div class="modal-mask" @touchmove.stop.prevent="disableScroll" @click="handleClose"></div>
      <div class="modal" @touchmove.self.prevent="disableScroll">
        <div class="modal-content">
          <div class="modal-left" @touchmove.stop.prevent="disableScroll" ><span class="rotate">{{ currentMember.position }}</span></div>
          <div class="modal-photo">
            <img :src="currentMember.image" />
          </div>
          <div class="modal-right">
            <div class="modal-right--name">{{ currentMember.realname }}</div>
            <div class="modal-right--position">{{ currentMember.position }}</div>
            <div class="modal-right--desc">{{ currentMember.intro }}</div>
          </div>
        </div>
        <div class="modal-close" @click="handleClose">
          <img class="icon-close" src="../../assets/images/home/icon_close.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import mixin from '@/mixins/mixin'

export default {
  name: 'TeamModule',
  mixins: [mixin],
  data() {
    return {
      showMemberCard: false,
      currentMember: {}
    }
  },
  computed: {
    ...mapGetters([
      'homeData'
    ])
  },
  methods: {
    handleMemberCard(item) {
      this.currentMember = item
      this.showMemberCard = true
      if (!this.isMobile) {
        this.stopMove()
      }
    },
    handleClose() {
      this.showMemberCard = false
      if (!this.isMobile) {
        this.canMove()
      }
    },
    disableScroll() {},
    // 停止页面滚动
    stopMove() {
      let m = function(e) {
        e.preventDefault();
      };
      document.body.style.overflow = 'hidden';
      document.addEventListener('touchmove', m, { passive: false });
    },
    // 开启页面滚动
    canMove() {
      let m = function(e) {
        e.preventDefault();
      };
      document.body.style.overflow = '';
      document.removeEventListener('touchmove', m, { passive: true });
    }
  }
}
</script>

<style scoped lang="scss">
.card-modal {
  .modal-mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(55,55,55,.6);
    height: 100%;
    z-index: 1000;
  }
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    margin: -300px 0 0 -500px;
    width: 1000px;
    height: 600px;
    background: #0e2048;
    z-index: 1000;
    .modal-content {
      display: flex;
      width: 100%;
      height: 100%;
      .modal-left {
        position: relative;
        width: 90px;
        font-weight: 700;
        font-size: 20px;
        line-height: 36px;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        background: #0D0E12;
        .rotate {
          transform: rotate(-90deg);
          display: inline-block;
          white-space: nowrap;
          height: 90px;
          width: 600px;
          line-height: 90px;
          position: absolute;
          left: -256px;
          top: 254px;
          text-align: end;
          padding-right: 30px;
        }
      }
      .modal-photo {
        position: relative;
        width: 360px;
        background: #80848a;
        img {
          width: 100%;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }
      .modal-right {
        flex: 1;
        padding: 0 32px;
        overflow-y: auto;
        text-align: left;
        &--name {
          margin-top: 180px;
          font-weight: 700;
          font-size: 28px;
          line-height: 36px;
          color: #fff;
        }
        &--position {
          margin-bottom: 14px;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-transform: uppercase;
          color: #fff;
          opacity: 0.2;
        }
        &--desc {
          flex: 1;
          padding-bottom: 20px;
          font-size: 14px;
          line-height: 32px;
          text-align: justify;
          color: #fff;
          opacity: 0.7;
        }
      }
    }
    .modal-close {
      position: absolute;
      width: 64px;
      height: 64px;
      left: 50%;
      margin-left: -32px;
      bottom: -124px;
      border-radius: 32px;
      background: #fff;
      opacity: 0.8;
      cursor: pointer;
      .icon-close {
        width: 100%;
        height: 100%;
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .card-modal .modal {
    margin: -250px 0 0 -40%;
    width: 80%;
    height: 500px;
    .modal-content {
      .modal-left {
        width: 60px;
        font-size: 18px;
        .rotate {
          height: 60px;
          line-height: 60px;
          width: 500px;
          left: -220px;
          top: 220px;
        }
      }
      .modal-photo {
        display: none;
      }
      .modal-right {
        padding: 20px;
        height: 500px;
        overflow-y: auto;
        overflow-x: hidden;
        &--name {
          margin-top: 10px;
        }
        &--desc {
          line-height: 20px;
        }
      }
    }
    .modal-close {
      width: 40px;
      height: 40px;
      margin-left: -20px;
      bottom: -62px;
      border-radius: 20px;
    }
  }
}
</style>