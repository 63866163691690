const lang = {
  name: '繁体首页',
  common: {
    nav: {
      home: '繁体首页'
    }
  },
  home: {
    aboutUs: '關於我們',
    aboutUsIntroduce1: 'Uollar 致力於以創新金融科技服務全球用戶，將人工智能、大數據、區塊鍊等最新技術應用於數字金融生態中，提供數字原生的金融服務以及相關基礎設施，幫助機構及個人用戶創造和積累數位財富，暢享財務自由新生活，以智能科技帶領用戶通往未來金融世界。',
    aboutUsIntroduce2: 'Uollar 團隊擁有豐富的金融投資和服務經驗，國際化的背景保證了前瞻性的思維，對最新數字金融行業的發展有敏銳的觸覺，可更有效地應對市場的變化。',
    description: '平台描述',
    descriptionIntroduce1: 'Uollar 提供綜合加密金融服務。我們幫助個人、交易所、礦池、代幣發行人、對沖基金和機構等, 進行交易和管理加密貨幣。<br>Uollar 是您通向加密金融領域的首選。',
    richProducts: '多樣化理財產品',
    richProductsDesc: 'Uollar 提供多樣化的理財產品可供使用者選擇。',
    fixIncomeOrientedProducts: '固定收益類產品',
    fixIncomeOrientedProductsDesc: '其中的一個主要類別是固定收益類產品，簡單穩定，而且上手簡單無障礙。',
    intuitiveApp: '精心設計的APP',
    intuitiveAppDesc: '通過 Uollar 精心設計的 APP，可以通過直觀簡單的操作來購買這些產品。',
    ourTeam: '團隊介紹',
    ourTeamIntroduce1: 'Uollar 團隊在金融投資和服務方面有著豐富經驗。',
    discover: '發掘',
    discoverDesc: '發掘市場上的優秀產品，並提供給使用者選擇',
    incubate: '孵化',
    incubateDesc: '發現、培育及孵化有潛力的投資團隊，從量化到固收到其它。',
    cooperate: '合作',
    cooperateDesc: '與多家交易所合作，有效利用虛擬貨幣，取得穩定收益。',
    riskControl: '風險控制',
    riskControlDesc: '信息安全監控。',
    riskControlStep1: '業務風控系统',
    riskControlStep2: '安全狀態偵測',
    riskControlStep3: '專業的資訊安全團隊',
    riskControlStep4: '完善的資訊安全防護體系',
    riskControlStep5: '可靠的管理系统',
    news: '相關報導',
    newsCardTitle: '軟銀向AI公司Qraft投資1.46億美元',
    newsCardDesc: '當地時間週一，美股收盤漲跌不一，納指日內跌幅一度接近3%，收盤小幅上漲，美債繼續遭拋售。日本軟銀集團將向人工智慧創業公司Qraft Technologies投入1.46億美...',
    readMore: 'READ MORE →',
    contactUs: '聯絡我們',
    contactUsDesc: '保持聯系'
  }
}
export default lang